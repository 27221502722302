<template>
  <div class="login-view">
      <div class="header">
          <div class="system-logo">
              <img src="@/assets/images/header/sys-logo.png" alt="安优智控平台">
          </div>
      </div>
      <div class="login">
          <div class="container">
            <BorderBox :bg="true">
              <div class="mode">
                  <div class="title">
                    <div class="text">用户登陆</div>
                    <img src="@/assets/images/login/effect.png" alt="effect">
                  </div>
                  <div class="form-box">
                      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="login-rule-form" @submit.native.prevent>
                        <el-form-item prop="username">
                          <el-input v-model="ruleForm.username" placeholder="请输入账号">
                              <i slot="prefix"><img src="@/assets/images/login/user.png"></i>
                          </el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                          <el-input v-model="ruleForm.password" show-password  placeholder="请输入密码">
                             <i slot="prefix"><img src="@/assets/images/login/pwd.png"></i>
                          </el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" native-type="submit" @click="submitForm('ruleForm')">登陆</el-button>
                        </el-form-item>
                      </el-form>
                      <div class="err-msg">{{errmsg}}</div>
                  </div>
              </div>
            </BorderBox>
          </div>
      </div>
  </div>
</template>

<script>

import BorderBox from '@/components/BorderBox.vue';

import { mapActions } from 'vuex';

export default {
  name: 'LoginView',
  components: { BorderBox },
  data(){
    return {
        errmsg: '', //后台接口传入登陆异常信息
        ruleForm: {
          username: '', //用户名
          password: '', //密码
        },
        rules: { //非空校验
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
          ]
        }
    }
  },
  methods: {
    ...mapActions(['setUserInfoAsync']),
    //表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginHandle(this.ruleForm);
        } else {
          return false;
        }
      });
    },

    //登陆请求
    async loginHandle(params){
       let [res, err] = await this.$Fx('/Api_Area/users/users/quickLogin', params);
       if(!err && res){
          //登陆成功后将用户信息存在 sessionStorage 中
          sessionStorage.setItem('area_user_info', JSON.stringify(res.data.area_user_info));
          const userInfo = res.data.area_user_info;
          this.setUserInfoAsync(userInfo);

          this.errmsg = '';
          this.$router.push('/dashboard');
       }else{
         this.errmsg = err.data.msg;
       }
    }

  }
}

</script>

<style lang="less" scope>
.login-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
  background: url('../../assets/images/login/bg.png') no-repeat;
  background-size: 100% 100%;

 .header{
    width: 100%;
    height: 70px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../assets/images/header/header-bg.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    .system-logo{
        display: flex;
        align-items: center;
        justify-content: center;
    }
 }
 .login{
   flex: 1 1 auto;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-bottom: 2rem;
   .container{
     width:480px;
     height: 360px;
     padding: 30px;
   }
   .mode{
     padding: 30px;
     width: 100%;
     height: 100%;
     box-sizing: border-box;
     .title{
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;
       .text{
          font-size: 22px;
          font-family: 'Alibaba-PuHuiTi-Bold';
          font-weight: bold;
       }
     }
     .form-box{
       padding: 20px 50px;
       .err-msg{
         text-align: center;
         font-size: 14px;
         color: #F56C6C;
       }
     }
   }
 }
 .login-rule-form{
   font-family: 'Alibaba-PuHuiTi-Regular';
   .el-form-item{
     margin-bottom: 25px;
   }
   .el-input__inner{
      background: rgba(76, 156, 255, 0.2);
      border: 1px solid #337bbe;
      border-radius: 3px;
      color: #fff;
      height: 42px;
      line-height: 42px;
      padding-left: 45px;
   }
   .el-input__prefix{
     width: 45px;
     line-height: 45px;
   }
   .el-button{
      width: 100%;
      height: 42px;
      background: rgba(22, 219, 255, 0.2);
      border: 1px solid #16DBFF;
      border-radius: 3px;
      margin-top: 20px;
      &:hover{
        background: rgba(22, 219, 255, 0.4);
      }
   }
 }
}

.el-input__inner::-webkit-input-placeholder {
  color: #fff !important;
}

.el-input__inner:-moz-placeholder{
  color: #fff !important;
}

.el-input__inner::-moz-placeholder {
  color: #fff !important;
}

.el-input__inner:-ms-input-placeholder {
  color: #fff !important;
}

</style>
